
import {
  AnalyticsEvent,
  LiveViewWidgetSettingsKeys,
  WidgetType,
  CameraStatus,
} from "@evercam/shared/types"

export default {
  props: {
    widget: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      settings: {
        fullscreen: false,
        download: false,
        zoom: false,
        playpause: false,
        refresh: 5,
      },
      refreshRateItems: [
        { text: "5 seconds", value: 5 },
        { text: "10 seconds", value: 10 },
        { text: "30 seconds", value: 30 },
        { text: "1 Minute", value: 60 },
      ],
    }
  },
  computed: {
    isCameraInactive(): boolean {
      return [
        CameraStatus.Offline,
        CameraStatus.OfflineScheduled,
        CameraStatus.UnderMaintenance,
        CameraStatus.WaitingForSiteVisit,
        CameraStatus.Waiting,
        CameraStatus.OnHold,
      ].includes(this.widget.ressources?.camera?.status)
    },
  },
  watch: {
    settings: {
      deep: true,
      handler(value) {
        this.$emit("settings-changed", value)
      },
    },
    isCameraInactive: {
      deep: true,
      immediate: true,
      handler(value) {
        if (value) {
          this.settings.playpause = false
        }
      },
    },
  },
  mounted() {
    for (const key of Object.keys(this.widget.settings)) {
      if (
        Object.values(LiveViewWidgetSettingsKeys)?.includes(key) &&
        this.widget.settings[key] !== undefined
      ) {
        this.settings[key] = this.widget.settings[key]
      }
    }
  },
  methods: {
    onDownloadChange() {
      this.$analytics.saveEvent(AnalyticsEvent.WidgetsSettingToggleDownload, {
        widget: WidgetType.LiveWidget,
        hasDownload: this.settings.download,
      })
    },
    onFullScreenChange() {
      this.$analytics.saveEvent(AnalyticsEvent.WidgetsSettingToggleFullScreen, {
        widget: WidgetType.LiveWidget,
        hasFullScreen: this.settings.fullscreen,
      })
    },
    onPlayPauseChange() {
      this.$analytics.saveEvent(AnalyticsEvent.WidgetsSettingTogglePlayPause, {
        widget: WidgetType.LiveWidget,
        hasPlayPause: this.settings.playpause,
      })
    },
    onRefreshChange() {
      this.$analytics.saveEvent(AnalyticsEvent.WidgetsSettingRefreshRate, {
        widget: WidgetType.LiveWidget,
        refreshEveryInMs: this.settings.refresh,
      })
    },
    onZoomChange() {
      this.$analytics.saveEvent(AnalyticsEvent.WidgetsSettingToggleZoom, {
        widget: WidgetType.LiveWidget,
        hasZoom: this.settings.zoom,
      })
    },
  },
}
