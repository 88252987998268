
import Textarea from "@evercam/shared/components/Textarea"

export default {
  components: {
    Textarea,
  },
  props: {
    widget: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    embedCode() {
      if (
        !this.widget?.id ||
        !this.widget?.widgetKey ||
        !this.widget?.widgetId
      ) {
        return ""
      }

      // eslint-disable-next-line no-useless-escape
      return `<div class="ec-container"><script src="${window.location.origin}/widget.js?widgetKey=${this.widget.widgetKey}&widgetId=${this.widget.widgetId}" async><\/script></div>`
    },
    iframeCode() {
      if (
        !this.widget?.id ||
        !this.widget?.widgetKey ||
        !this.widget?.widgetId
      ) {
        return ""
      }

      // eslint-disable-next-line no-useless-escape
      return `<iframe allow="fullscreen" sandbox="allow-scripts allow-same-origin" style="width: 100%; minHeight: 350px; height: 100%; border: none" src="${window.location.origin}/v2/widgets?widgetKey=${this.widget.widgetKey}&widgetId=${this.widget.widgetId}&isWidget=true" />`
    },
    widgetLink() {
      if (
        !this.widget?.id ||
        !this.widget?.widgetKey ||
        !this.widget?.widgetId
      ) {
        return ""
      }

      // eslint-disable-next-line no-useless-escape
      return `${window.location.origin}/v2/widgets?widgetKey=${this.widget.widgetKey}&widgetId=${this.widget.widgetId}&isWidget=true`
    },
  },
  methods: {
    setDialogState(event) {
      if (!event) {
        this.$emit("close")
      }
    },
  },
}
