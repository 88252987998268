
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import {
  AnalyticsEvent,
  WidgetType,
  DelayUnit,
  RecordingsWidgetSettingsKeys,
  CameraStatus,
} from "@evercam/shared/types"

export default {
  components: {
    EvercamLoadingAnimation,
  },
  props: {
    widget: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      delayUnitItems: [
        { text: this.$t("content.minutes"), value: DelayUnit.Minute },
        { text: this.$t("content.hours"), value: DelayUnit.Hour },
        { text: this.$t("content.days"), value: DelayUnit.Day },
      ],
      settings: {
        hasDelay: false,
        delay: 5,
        delayUnit: DelayUnit.Minute,
        darkMode: false,
        calendar: false,
        hasRange: false,
        dateRange: null,
      },
      dateCursor: null,
      editValueDebounce: null,
      editValueDebounceTimeOut: 400,
      isLoadingDays: false,
      availableDays: [],
    }
  },
  computed: {
    isCameraDecommissioned(): boolean {
      return (
        this.widget.ressources?.camera?.status === CameraStatus.Decommissioned
      )
    },
  },
  watch: {
    settings: {
      deep: true,
      handler(value) {
        this.$emit("settings-changed", value)
      },
    },
    isCameraDecommissioned: {
      immediate: true,
      handler(value) {
        if (value) {
          this.settings.hasDelay = false
        }
      },
    },
    async dateCursor(date) {
      const isMonthView =
        this.$moment(date, "YYYY-MM").format("YYYY-MM") === date

      if (!isMonthView || this.isLoadingDays) {
        this.availableDays = []

        return
      }

      this.isLoadingDays = true
      await this.fetchAvailableDaysForMonth(date)
      this.isLoadingDays = false
    },
    "widget.ressources.camera.exid": "fetchLatestDate",
  },
  mounted() {
    for (const key of Object.keys(this.widget.settings)) {
      if (
        Object.values(RecordingsWidgetSettingsKeys)?.includes(key) &&
        this.widget.settings[key] !== undefined
      ) {
        this.settings[key] = this.widget.settings[key]
      }
    }
    this.fetchLatestDate(this.widget?.ressources?.camera?.exid)
  },
  methods: {
    onDarkModeChange() {
      this.$analytics.saveEvent(
        AnalyticsEvent.WidgetsSettingToggleRecordingsDarkMode,
        {
          widget: WidgetType.Recording,
          hasDarkMode: this.settings.darkMode,
        }
      )
    },
    onDelayChange() {
      clearTimeout(this.editValueDebounce)
      this.editValueDebounce = setTimeout(() => {
        this.saveDelayAnalyticsEvent()
      }, this.editValueDebounceTimeOut)
    },
    onDelayUnitChange() {
      this.saveDelayAnalyticsEvent()
    },
    onHasDelayChange() {
      this.$analytics.saveEvent(
        AnalyticsEvent.WidgetsSettingToggleRestrictRecentImages,
        {
          widget: WidgetType.Recording,
          hasDelay: this.settings.hasDelay,
        }
      )
    },
    onSidebarChange() {
      this.$analytics.saveEvent(
        AnalyticsEvent.WidgetsSettingToggleRecordingsCalendar,
        {
          widget: WidgetType.Recording,
          hasCalendar: this.settings.calendar,
        }
      )
    },
    saveDelayAnalyticsEvent() {
      this.$analytics.saveEvent(
        AnalyticsEvent.WidgetsSettingRestrictRecentImagesInput,
        {
          widget: WidgetType.Recording,
          delay: this.settings.delay,
          delayUnit: this.settings.delayUnit,
        }
      )
    },
    formatDateRange(dateRange) {
      if (!dateRange || dateRange.length !== 2) {
        return ""
      }

      return `${this.formatDate(dateRange[0])} - ${this.formatDate(
        dateRange[1]
      )}`
    },
    formatDate(date, format = "DD-MM-YYYY") {
      return this.$moment(date).isValid()
        ? this.$moment(date).format(format)
        : "-"
    },
    isDateAvailable(date) {
      if (this.isLoadingDays) {
        return true
      }

      return this.availableDays?.includes(
        Number.parseInt(this.$moment(date).format("D"))
      )
    },
    async fetchAvailableDaysForMonth(date) {
      const m = this.$moment(date)
      const year = m.format("YYYY")
      const month = m.format("MM")

      try {
        const { days } = await EvercamApi.recordings.availableDays({
          cameraId: this.widget?.ressources?.camera?.exid,
          year,
          month,
        })
        this.availableDays = days
      } catch (error) {
        this.$errorTracker.save(error)
      }
    },
    async fetchLatestDate(cameraId) {
      if (!cameraId) {
        return
      }
      try {
        const { createdAt } = await EvercamApi.recordings.latest(cameraId, {
          includeImage: false,
        })
        this.dateCursor = this.$moment.utc(createdAt).format("YYYY-MM")
      } catch (error) {
        this.$errorTracker.save(error)
      }
    },
    dateRangeChanged(dates) {
      if (
        dates?.length === 2 &&
        this.$moment(dates[0]).isAfter(this.$moment(dates[1]))
      ) {
        this.settings.dateRange = [dates[1], dates[0]]

        return
      }
      this.settings.dateRange = dates
    },
    dateRangeMenuChanged(state) {
      if (!state && this.settings.dateRange?.length === 1) {
        this.settings.dateRange = [
          this.settings.dateRange[0],
          this.settings.dateRange[0],
        ]
      }
    },
  },
}
