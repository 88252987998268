
import { mapStores } from "pinia"
import { useBimCompareStore } from "@/stores/bimCompare"
import {
  AnalyticsEvent,
  BimCompareWidgetSettingsKeys,
  WidgetType,
} from "@evercam/shared/types"

export default {
  props: {
    widget: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      is4DBimModel: true,
      settings: {
        timeline: false,
        calendar: false,
        darkMode: false,
        width: 100,
        resolution: "%",
        height: 100,
        heightResolution: "%",
      },
    }
  },
  computed: {
    ...mapStores(useBimCompareStore),
  },
  watch: {
    settings: {
      deep: true,
      immediate: true,
      handler(value) {
        this.$emit("settings-changed", value)
      },
    },
    "bimCompareStore.has4D": {
      handler(value) {
        if (!value) {
          this.settings.timeline = value
          this.is4DBimModel = false

          return
        }
        this.is4DBimModel = true
      },
      immediate: true,
    },
  },
  mounted() {
    for (const key of Object.keys(this.widget.settings)) {
      if (
        Object.values(BimCompareWidgetSettingsKeys)?.includes(key) &&
        this.widget.settings[key] !== undefined
      ) {
        this.settings[key] = this.widget.settings[key]
      }
    }
  },
  methods: {
    onSidebarChange() {
      this.$analytics.saveEvent(
        AnalyticsEvent.WidgetsSettingToggleBIMCompareCalendar,
        {
          widget: WidgetType.BimCompare,
          hasCalendar: this.settings.calendar,
        }
      )
      this.$emit("input", this.widget)
    },
    onDarkModeChange() {
      this.$analytics.saveEvent(
        AnalyticsEvent.WidgetsSettingToggleBIMCompareDarkMode,
        {
          widget: WidgetType.BimCompare,
          hasDarkMode: this.settings.darkMode,
        }
      )
      this.$emit("input", this.widget)
    },
    onTimelineChange() {
      this.$analytics.saveEvent(
        AnalyticsEvent.WidgetsSettingToggleBIMCompareTimeLine,
        {
          widget: WidgetType.BimCompare,
          hasTimeline: this.settings.timeline,
        }
      )
      this.$emit("input", this.widget)
    },
  },
}
